import React, { useContext, useEffect, useState } from "react";
import { update } from "../../api/PageAPI";
import ErrorMessage from "../ErrorMessage";
import PageEditDto from "../../api/dtos/PageEditDto";
import { usePageFetch } from "./usePageFetch";
import { LoadingState } from "../../common/LoadingState";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spinner } from "../common/Spinner";
import { AddressBar } from "./AddressBar";
import { PageBlockSpinner } from "../common/PageBlockSpinner";
import DefaultButton from "../common/DefaultButton";
import { TimelineTemplate } from "./theme/TimelineTemplate";
import { TemplateName } from "../create/TemplateName";
import { AnniversaryTemplate } from "./theme/anniversary/AnniversaryTemplate";
import { EditPageState } from "./state/EditPageState";
import { PageItemType } from "./dto/PageItemType";
import { observer } from "mobx-react-lite";
import { generateRandomString } from "../../common/GenerateHash";
import { Photo } from "../../api/dtos/Photo";
import { Quote } from "../../api/dtos/Quote";
import { AddItemsCircleButton } from "./AddItemsCircleButton";
import EditPageHeader from "../header/EditPageHeader";
import { IoMdPlay } from "react-icons/io";
import colors from "../colors";
import { useTranslation } from "react-i18next";
import { AppContext } from "../AppContext";

type Props = {
  editPageState: EditPageState;
};
const EditPage: React.FC<Props> = observer(({ editPageState }) => {
  const [searchParams] = useSearchParams();
  const { state } = useContext(AppContext);
  const pageId = parseInt(searchParams.get("pageId") || "");
  const pageViewState = usePageFetch(pageId);

  const [error, setError] = useState(null);
  const [isSaveButtonSpin, setSaveButtonSpin] = useState(false);
  const [isWarnEmptyFields, setWarnEmpty] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (state.userDto.state === LoadingState.LOADED) {
    console.log(state.userDto.data);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pageViewState.state === LoadingState.LOADED) {
      const items: (Photo | Quote)[] = [...pageViewState.data.quotes, ...pageViewState.data.photos].sort((a, b) => a.pageIndex - b.pageIndex);
      editPageState.setItems(
        items.map((item) => {
          if ("text" in item) {
            return {
              type: PageItemType.QUOTE,
              key: generateRandomString(),
              data: item.text,
            };
          } else {
            return {
              type: PageItemType.PHOTO,
              key: generateRandomString(),
              data: item.data,
            };
          }
        })
      );
      editPageState.setTemplateName(pageViewState.data.templateName);
    }
  }, [pageViewState.state]);

  async function handlePageSave() {
    setWarnEmpty(true);
    const emptyItems = editPageState.emptyItems;

    if (emptyItems.length > 0) {
      scrollToFirstInvalidField();
      return;
    }

    setSaveButtonSpin(true);

    if (pageViewState.state !== LoadingState.LOADED) {
      return;
    }
    const page: PageEditDto = {
      ...pageViewState.data,
      quotes: editPageState.items
        .map((item, index) => {
          if (item.type !== PageItemType.QUOTE) {
            return null;
          }
          return {
            key: item.key,
            pageIndex: index,
            text: item.data,
            position: "left",
          };
        })
        .filter((item) => !!item),
      photos: editPageState.items
        .map((item, index) => {
          if (item.type !== PageItemType.PHOTO) {
            return null;
          }
          return {
            key: item.key,
            pageIndex: index,
            data: item.data,
            position: "left",
          };
        })
        .filter((item) => !!item),
    };

    update(page)
      .then((response) => {
        if (response.status === 200) {
          if (state.userDto.state === LoadingState.LOADED && state.userDto.data?.deviceId === state.userDto.data.email) {
            navigate("/create-account");
          } else {
            navigate("/dashboard");
          }
        } else {
          setError("Something went wrong");
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setSaveButtonSpin(false);
      });
  }

  function scrollToFirstInvalidField() {
    setTimeout(() => {
      const elements = Array.from(document.querySelectorAll(".EditPage-Image, .EditPage-Quote"));
      const firstEmptyItem = editPageState.emptyItems[0];
      const firstEmptyItemIndex = editPageState.items.indexOf(firstEmptyItem);
      const firstEmptyElementIndex = editPageState.templateName === TemplateName.TIMELINE ? firstEmptyItemIndex : firstEmptyItemIndex - 14;
      const element = elements[firstEmptyElementIndex];
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }
    }, 100);
  }

  function doScrollToLastElement() {
    const elements = Array.from(document.querySelectorAll(".EditPage-Image, .EditPage-Quote"));
    const lastIndex = elements.length - 1;
    if (lastIndex < 0) {
      return;
    }
    const element = document.querySelector("#last-element-to-scroll");

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
  }

  function scrollToLastElement() {
    setTimeout(() => {
      doScrollToLastElement();
    }, 200);
  }

  function handleAddPhotoClick(): void {
    editPageState.addPhotoItem();
    scrollToLastElement();
  }

  function handleAddTextClick(): void {
    editPageState.addQuoteItem();
    scrollToLastElement();
  }

  if (pageViewState.state === LoadingState.LOADING) {
    return <Spinner />;
  }

  const saveButtonIcon = <IoMdPlay color={colors.white2} size={25} />;

  return (
    <div className="EditPage">
      <div className="EditPage-Navbar">
        <EditPageHeader>
          <DefaultButton
            text={t("save-button")}
            disabled={isSaveButtonSpin}
            icon={saveButtonIcon}
            size="large"
            color="green"
            onClick={handlePageSave}
          />
        </EditPageHeader>
        <div className="EditPage-Address">
          <AddressBar pageId={pageId} />
        </div>
      </div>
      <div className="EditPage-Content">
        {isSaveButtonSpin && <PageBlockSpinner />}
        {!!error && <ErrorMessage message={error} />}
        {editPageState.templateName === TemplateName.TIMELINE && <TimelineTemplate isWarnEmptyFields={isWarnEmptyFields} state={editPageState} />}

        {editPageState.templateName === TemplateName.ANNIVERSARY && (
          <AnniversaryTemplate isWarnEmptyFields={isWarnEmptyFields} state={editPageState} />
        )}
      </div>
      <AddItemsCircleButton onPhotoClick={handleAddPhotoClick} onTextClick={handleAddTextClick} />
      <div style={{ height: "130px", width: "100%" }} id="last-element-to-scroll" />
      <style jsx>
        {`
          .EditPage-Content {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
          }
          .EditPage-Navbar {
            width: 100%;
            box-sizing: border-box;
            position: fixed;
            top: 0;
            background: #3a6186; /* fallback for old browsers */
            /* Chrome 10-25, Safari 5.1-6 */

            background: -webkit-linear-gradient(to right, #89253e, #3a6186);

            /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+,
                Opera 12+, Safari 7+ */

            background: linear-gradient(to right, #89253e, #3a6186);

            z-index: 5;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          .EditPage-Navbar + .EditPage-Content {
            padding-top: 9em;
          }
          .EditPage-Address {
            padding: 0.5em 1em;
            width: 100%;
            box-sizing: border-box;
          }
          .EditPage {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            width: 100%;
            margin-bottom: 3em;
            min-height: 70vh;
            box-sizing: border-box;
          }
          @media only screen and (max-width: 750px) {
            .EditPage-Image,
            .EditPage-Quote {
              padding: 1em;
            }
            .EditPage {
              width: calc(100%);
            }
          }

          @media only screen and (min-width: 750px) {
            .EditPage {
              width: 70%;
              min-width: 610px;
              max-width: 750px;
            }
            .EditPage-Address {
              width: 70%;
              max-width: 700px;
            }
          }
        `}
      </style>
    </div>
  );
});

export default EditPage;
