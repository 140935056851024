import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../common/DefaultButton";
import { AppContext } from "../AppContext";
import { LoadingState } from "../../common/LoadingState";
import colors from "../colors";
import { AiFillStar } from "react-icons/ai";
import { Footer } from "./Footer";
import Header from "../header/Header";
import { HomepageTemplates } from "./HomepageTemplates";
import { css } from "@emotion/css";
import "@fontsource/barlow-semi-condensed";
import { FeatureColumns } from "./FeatureColumns";
import { HappyCustomers } from "./HappyCustomers";
import { SecondFeatureColumns } from "./SecondFeatureColumns";
import { useTranslation } from "react-i18next";

const initialProfiles = [
  "/images/cute-7441224_640.jpg",
  "/images/girl-748932_1280.jpg",
  "/images/girl-6486470_1280.jpg",
  "/images/jazz-1772327_1280.jpg",
  "/images/man-1845814_1280.jpg",
  "/images/man-1948310_1280.jpg",
  "/images/man-6781830_1280.jpg",
  "/images/man-6870218_1280.jpg",
  "/images/women-2808641_1280.jpg",
];

const HomePage: React.FC = () => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [profiles, setProfiles] = useState<string[]>(initialProfiles);
  const [positions, setPositions] = useState<("top" | "bottom")[]>([]);

  const { pathname } = window.location;
  if (pathname !== "/") {
    navigate("/");
  }

  useEffect(() => {
    if (state.userDto.state === LoadingState.LOADED) {
      navigate("/dashboard");
      return;
    }

    // Select the element to be animated
    const element = document.querySelector(".Select-Template-Text");

    // Create a new Intersection Observer
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // If the element is visible, add the class to trigger the animation
          element.classList.add("Animate-Typing");
        } else {
          // If the element is not visible, remove the class
          // element.classList.remove("Animate-Typing");
        }
      });
    });

    // Start observing the element
    observer.observe(element);
  }, [state.userDto]);

  let countdownTargetDate = "2025-02-18 00:00:00";

  const calculateTimeLeft = () => {
    const difference = new Date(countdownTargetDate).getTime() - new Date().getTime();
    if (difference <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [countdownTargetDate]);

  useEffect(() => {
    setPositions(initialProfiles.map((_, index) => (index % 2 === 0 ? "top" : "bottom")));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setProfiles((_profiles) => {
        const first = _profiles[0];
        const rest = _profiles.slice(1);
        return [...rest, first];
      });

      setPositions((_positions) => {
        const first = _positions[0];
        const rest = _positions.slice(1);
        return [...rest, first];
      });
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  function handleStartClick(): void {
    navigate("/templates");
  }

  const headlineStyle = css`
    text-align: left;
    width: 100%;
    max-width: 370px;
    padding: 0 1em;
    box-sizing: border-box;
    h2 {
      font-weight: bold;
      color: ${colors.grayDark2};
      margin: 1em 0 0.5em 0;
      font-family: "Barlow Semi Condensed", sans-serif;
      font-size: 1.8em;
    }
    span {
      color: ${colors.grayDark2};
    }
    h3 {
      font-weight: bold;
      margin: 0 0 1em 0;
      font-size: 1em;
      line-height: 1.5em;
    }
    h3 > span {
      color: ${colors.grayDark2};
      font-style: italic;
      font-weight: normal;
    }
  `;

  const firstPhotoContainerStyle = css`
    width: fit-content;
    height: fit-content;
    position: relative;
  `;

  const firstPhotoStyle = css`
    width: 100%;
    height: 40vh;
    max-width: 350px;
    max-height: 440px;
    object-fit: contain;
    @media (min-width: 550px) {
      max-width: 80vw;
      max-height: 100%;
    }
    border-radius: 5px;
  `;

  const secondFeaturesHeadlineStyle = css`
    width: 100%;
    font-size: 1.5em;
    padding: 3em 1em 0em 1em;
    box-sizing: border-box;
    h3 {
      margin: 0;
      line-height: 1.4em;
    }
    p {
      font-size: 0.7em;
      line-height: 1.5em;
    }
  `;

  const tryOutTextStyle = css`
    margin: 1em 0 0 0;
    padding: 1em 1em 0.5em 1em;
    font-size: 1.2em;
    line-height: 1.5em;
    h3 {
      margin: 0;
      display: inline;
    }
    text-align: left;
    width: 90%;
  `;

  return (
    <div className="HomePage">
      <div className="discount">
        <span>🎁{t("valentines-day-special-discount")}🎁</span>
        <span>
          <div>
            {timeLeft.days} {t("days")} {String(timeLeft.hours).padStart(2, "0")}:{String(timeLeft.minutes).padStart(2, "0")}:
            {String(timeLeft.seconds).padStart(2, "0")}🔥
          </div>
        </span>
      </div>
      <Header />
      <div className={headlineStyle}>
        {currentLanguage === "en" && (
          <h2>
            Create an <span>I love you</span> website
          </h2>
        )}
        {currentLanguage === "tr" && (
          <h2>
            <span>Seni seviyorum </span> sürprizi hazırla
          </h2>
        )}
        <div className={"div1"}>
          <span> {t("young-and-romantic")} </span>
        </div>
        <div className={"div2"}>
          <span> {t("no-coding-skills-required")} </span>
        </div>
      </div>
      <div className={firstPhotoContainerStyle}>
        <img
          className={firstPhotoStyle}
          pre-load="true"
          src="https://ik.imagekit.io/ex5o5cwj51/main-image-small.jpeg?updatedAt=1698003807847"
          alt=""
        />
      </div>
      <div className="HomePage-Start-Btn">
        <DefaultButton className="Get-Started-Btn" color="gradient" text={t("get-started")} onClick={handleStartClick} />
      </div>
      <div className="Info-Row">
        <div className="Info-Row-First-Column">
          +3,000 Websites <br /> Created
        </div>
        <div className="Info-Row-Second-Column">
          <div className="Review-Container">
            <div className="Review-First-Row">Customer Satisfaction Rate</div>
            <div className="Review-Second-Row">
              <div className="review-container">
                <div className="Review-Text">4.8</div>
                <div className="rating">
                  {[0, 0.2, 0.4, 0.6, 0.8].map((delay, index) => (
                    <span key={index} className={`star ${index === 4 ? "half" : ""}`} style={{ "--delay": `${delay}s` } as React.CSSProperties} />
                  ))}
                </div>
                <div className="profile-container">
                  {profiles.map((src, index) => (
                    <img key={src} src={src} alt="User" className={`profile-img ${positions[index]}`} />
                  ))}
                </div>
              </div>
            </div>
            <div className="Review-Third-Row">
              <div style={{ opacity: 0 }} className="Review-Text">
                5.0
              </div>
              <div>Based on 152 reviews.</div>
            </div>
          </div>
        </div>
      </div>
      <h2 className="Select-Template-Text">{t("start-with-a-template")}</h2>
      <div className="Demo-View-Container">
        <HomepageTemplates />
      </div>
      <div className="HomePage-Info-Area">
        <div className={secondFeaturesHeadlineStyle}>
          <h3>{t("why-lovepage-io")}</h3>
          <p>{t("why-lovepage-io-sub")}</p>
        </div>

        <FeatureColumns />

        <HappyCustomers />

        <div className={secondFeaturesHeadlineStyle}>
          <h3>{t("special-way-to-express-your-love")}</h3>
        </div>

        <SecondFeatureColumns />

        <div className="Homepage-Video-Tutorial">
          <h3>
            {t("watch-our-video-tutorial")}
            <br />
            {t("create-in-2-mins")}
          </h3>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/6tmIi0mF3Ys"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay;
             clipboard-write; encrypted-media;
              gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <p className={tryOutTextStyle}>
          {t("there-is-no-better-way")}
          &nbsp;<h3>{t("try-it-now")}</h3>
        </p>
        <div className="HomePage-Start-Btn">
          <DefaultButton className="Get-Started-Btn-2" color="gradient" text={t("get-started")} onClick={handleStartClick} />
          <span>No Credit Card required</span>
        </div>
      </div>

      <Footer />
      <style>
        {`
        @keyframes typing {
          from {
            width: 0;
          }
          to {
            width: 100%;
          }
        }
        .Homepage-Video-Tutorial {
          width: 100%;
          max-width: 600px;
          margin-top: 1em;
          margin-bottom: 2em;
          padding: 5px;
          box-sizing: border-box;
        }
        .Homepage-Video-Tutorial > * {
          width: 100%;
        }
        .Homepage-Video-Tutorial > iframe {
          margin-top: 1em;
        }
        .Homepage-Video-Tutorial > h3 {
          line-height: 1.5em;
          padding: 0 0.5em;
          box-sizing: border-box;
        }
          .Animate-Typing {
            text-align: left;
            box-sizing: border-box;
            line-height: 1.5em;
            animation: typing 1s steps(20, end) forwards;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            width: 0; 
          }
          .Select-Template-Text {
            margin-top: 2em;
            box-sizing: border-box;
            line-height: 1.5em;
            padding: 0 0.5em;
            text-align: center;
            margin-bottom: 0;
          }
          .Demo-View-Container {
            width: 100%;
            overflow-x: auto;
            border-bottom: 2px solid rgb(255, 255, 255, 0.5);
            shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
          }
          .Review-Second-Row {
            display: flex;
            font-size: 13px;
          }
          .Review-Stars {
            display: flex;
          }
          .Review-Text {
            font-size: 24px;
            font-weight: bold;
            color: ${colors.grayDark2};
          }
          .Review-Container {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            justify-content: center;
          }
          .Review-Second-Row,.Review-First-Row, .Review-Third-Row {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-family: Roboto,Arial,sans-serif;
            flex: 1;
            line-height: 1.3em;
          }
          .Info-Row-First-Column, .Info-Row-Second-Column {
            flex: 1;
          }
          .Info-Row-First-Column {
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: start;
            text-align: center;
            padding-left: 10px;
            line-height: 25px;;
          }
          .Info-Row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 80px;
            font-size: 14px;
            background-color: rgba(255, 255, 255, 0.2);
            margin-top: 1em;
          }
          .HomePage {
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            color: ${colors.grayDark2}
          }
          .HomePage-Start-Btn {
            padding: 4em 0 6em 0;
            width: 160px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5em;
            font-size: 0.8em;
          }
          .Satisfaction-Rate {
            font-size: 0.8em;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .HomePage-Info-Area {
            width: 100%;
            max-width: 750px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          @media (min-width: 550px) {
            .First-Screen {
              width: 550px;
            }
            .Info-Row {
              justify-content: space-around;
            }
            .Info-Row-First-Column {
              justify-content: center;
            }
            .Footer {
              flex-direction: row;
            }
          }

          .div1, .div2 {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.div1, .div2 > span {
  font-size: 1.6rem;
    font-weight: bold;
    background: -webkit-linear-gradient(45deg, #827ffc, #fb7f82 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.div1 {
    animation: fadeInUp 0.6s ease-out forwards;
    margin-top: 1rem;
}

.div2 {
    animation: fadeInUp 0.6s ease-out 0.6s forwards;
    margin-bottom: 1rem;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.review-container {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.rating {
  display: flex;
  gap: 5px;
  font-size: 24px;
  font-weight: bold;
}

.star {
  width: 30px;
  height: 30px;
  background: gray;
  clip-path: polygon(
    50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%,
    50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%
  );
  transition: background 0.5s ease-in-out, transform 0.5s ease-in-out;
  animation: fillStar 0.5s ease-in-out forwards, pop 0.5s ease-in-out forwards;
  animation-delay: var(--delay);
}

@keyframes fillStar {
  from {
    background: gray;
  }
  to {
    background: #b57fca;
  }
}

/* 5. yıldız için özel stil */
.star.half::after {
  content: "";
  position: absolute;
  width: 100%;
    height: 100%;
    background: gray;
  top: 0;
  left: 0;
  clip-path: polygon(
    50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%,
    50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%
  );
  transition: background 0.5s ease-in-out, transform 0.5s ease-in-out;
  animation: fillLastStar 0.5s ease-in-out forwards, pop 0.5s ease-in-out forwards;
  animation-delay: var(--delay);
}

@keyframes fillLastStar {
  from {
    background: gray;
  }
  to {
    background: linear-gradient(81deg, #b57fca, #9a73a9, gray);
  }
}

/* Büyüme animasyonu */
@keyframes pop {
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.profile-container {
  position: absolute;
    right: -75px;
    bottom: 60px;
    width: 200px;
    height: 114px;
    overflow: hidden;
}

.profile-img {
  position: absolute;
    right: -70px;
    width: 50px;
    margin-bottom: 1rem;
    margin-top: 1rem;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 0px 9px 5px #b57fca;
    animation: slideInOut 3s linear;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

@keyframes slideInOut {
  0% {
    right: -60px;
    opacity: 0;
  }
  20% {
    opacity: .75;
  }
  50% {
    right: 10px;
  }
  80% {
    opacity: .75;
  }
  100% {
    right: 80px;
    opacity: 0;
  }
}

@media (max-width: 550px) {
  .profile-container {
    right: 0;
    bottom: 40px !important;
            }

            .discount {
              flex-direction: column;
              align-items: center;
            }
}

.discount {
  width: 100%;
  display: flex;
  justify-content: center;
  background: black;
  color: white;
  padding: .75rem;
  box-sizing: border-box;
}

.discount > span {
  padding-right: 10px;
}
        `}
      </style>
    </div>
  );
};

export default HomePage;
