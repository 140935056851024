import React from "react";
import { Link } from "../common/Link";
import { SocialLinks } from "../page/SocialLinks";
import colors from "../colors";

export const Footer = () => {
  return (
    <div className="Footer">
      <Link href="https://privacy-policy.lovepage.io" text="Privacy Policy" />
      <Link href="https://terms-of-use.lovepage.io/t" text="Terms of Use" />
      <Link href="https://blog.lovepage.io" text="Blog" />
      <SocialLinks />© 2024 lovepage.io, NeoPages Tech LLC
      <style jsx>
        {`
          .Footer {
            width: 100%;
            height: wrap-content;
            padding: 1em;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 2em;
            font-size: 12px;
            color: #8c7ff3;
            border-image-slice: 1;
            border-top: 2px solid;
            box-sizing: border-box;
            border-image-source: linear-gradient(135deg, rgba(255, 0, 0, 0.5), rgba(0, 0, 255, 0.5));
          }
        `}
      </style>
    </div>
  );
};
