import React from "react";
import colors from "../colors";

type Props = {
  text: string;
  href: string;
};

export const Link: React.FC<Props> = ({ href, text }) => {
  return (
    <>
      <a href={href} className="Link">
        {text}
      </a>
      <style jsx>
        {`
          .Link {
            color: #c57fba !important;
            font-size: 1.2em;
            text-decoration: underline;
          }
        `}
      </style>
    </>
  );
};
