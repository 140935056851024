import React from "react";
import { AiFillTwitterSquare, AiOutlineInstagram } from "react-icons/ai";
import { BsTiktok, BsYoutube } from "react-icons/bs";
import colors from "../colors";

export const SocialLinks = () => {
  return (
    <span className="Social-Links">
      <a target="_blank" className="Social-Link" href="https://www.youtube.com/@LovePageio" rel="noreferrer">
        <BsYoutube color="white" size={30} />
      </a>
      <a target="_blank" className="Social-Link" href="https://www.twitter.com/lovepageio" rel="noreferrer">
        <AiFillTwitterSquare color="white" size={30} />
      </a>
      <a target="_blank" className="Social-Link" href="https://www.tiktok.com/@lovepage.io" rel="noreferrer">
        <BsTiktok color="white" size={22} />
      </a>
      <a target="_blank" className="Social-Link" href="https://www.instagram.com/lovepageio" rel="noreferrer">
        <AiOutlineInstagram color="white" size={30} />
      </a>
      <style jsx>
        {`
          .Social-Links {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 1.5em;
            background: linear-gradient(135deg, rgba(255, 0, 0, 0.5), rgba(0, 0, 255, 0.5));
            padding: 0.5rem;
            border-radius: 1rem;
          }

          .Social-Link svg:hover {
            fill: ${colors.gray};
          }
        `}
      </style>
    </span>
  );
};
