import React from "react";
import { TemplateViewActions } from "./TemplateViewActions";
import { TemplateName } from "./TemplateName";
import { Ribbon } from "../common/Ribbon";
import { MdWorkspacePremium } from "react-icons/md";
import colors from "../colors";

type Props = {
  showOverlay: boolean;
  templateName: TemplateName;
};

export const TemplateView: React.FC<Props> = ({ showOverlay, templateName }) => {
  const imgUrl =
    templateName === TemplateName.ANNIVERSARY
      ? "https://ik.imagekit.io/ex5o5cwj51/anni-1.jpeg?updatedAt=1685555650529"
      : "https://ik.imagekit.io/ex5o5cwj51/timeline-new.jpeg?updatedAt=1685816486832";
  return (
    <div className="TemplateView">
      {templateName === TemplateName.TIMELINE && (
        <Ribbon color={colors.lightBlue}>
          <span style={{ paddingRight: "1em", fontSize: "1.5em", color: colors.white }}>
            <b>FREE</b>
          </span>
        </Ribbon>
      )}

      {templateName === TemplateName.ANNIVERSARY && (
        <Ribbon color={colors.premiumPurple}>
          <span
            style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingRight: "1em", fontSize: "1.5em", color: colors.white }}
          >
            <MdWorkspacePremium size={25} />
            <b>Premium</b>
          </span>
        </Ribbon>
      )}
      <img style={{ borderColor: showOverlay ? "#5daafd" : "black" }} className="TemplateView-Img" src={imgUrl} />
      {showOverlay && (
        <div className="TemplateView-Overlay">
          <div className="TemplateView-Overlay-Actions">
            <TemplateViewActions alignment="vertical" templateName={templateName} className={`TemplateView-Overlay-Actions-${templateName}`} />
          </div>
        </div>
      )}
      <style>
        {`
        .TemplateView {
            width: 100%;
            height: 100%;
            max-width: 270px;
            max-height: 440px;
            box-sizing: border-box;
            position: relative;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: linear-gradient(90deg, #872640, #3a5f85);
        }
        .TemplateView-Img {
            width: 65vw;
            height: 60vh;
            max-width: 270px;
            max-height: 440px;
            object-fit: contain;
            border: 5px solid black;
            border-radius: 10px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
            box-sizing: border-box;
            display: flex;
        }
        .TemplateView-Overlay {
            position: absolute;
            top: 5px;
            left: 5px;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
        }
        .TemplateView-Overlay-Actions {
            width: 100%;
            padding: 0 4px;
        }
     `}
      </style>
    </div>
  );
};
